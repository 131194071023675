import React from 'react'

import { Style } from 'components/dataDisplay'

import InfoModal from '../../HomePage/components/InfoModal/InfoModal'
import AddHeroModal from '../../HomePage/components/AddHeroModal/AddHeroModal'
import AudioContextProvider from '../AudioContextProvider/AudioContextProvider'

import { useBgColor } from './util'

import './ImmortalsContainer.scss'

import bgImage from './images/bg.jpg'
import bgImage2x from './images/bg@2x.jpg'


const ImmortalsContainer: React.FC = (props) => {
  const { children } = props

  useBgColor()

  return (
    <>
      <AudioContextProvider>
        {children}
      </AudioContextProvider>
      <Style
        rules={[
          {
            selectors: [ '#root > div.bg-warrior', '#page-content.bg-warrior' ],
            bgImage,
            bgImage2x,
          },
        ]}
      />
      <InfoModal />
      <AddHeroModal />
    </>
  )
}


export default ImmortalsContainer
