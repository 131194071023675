import React, { ReactNode } from 'react'
import { array } from 'helpers'

import { Bone, Grid } from 'components/layout'


const defaultList = array.range(0, 7)

export type AdminCountersPageSkeletonProps = {
  withControls?: boolean
  withTabs?: boolean
  listSize?: number
  commonLayoutNode?: ReactNode
}

const AdminCountersPageSkeleton: React.FC<AdminCountersPageSkeletonProps> = (props) => {
  const { listSize, withControls = true, withTabs = true, commonLayoutNode } = props
  const list = typeof listSize === 'number'
    ? listSize ? array.range(1, listSize) : []
    : defaultList

  return (
    <>
      {commonLayoutNode}
      {
        withControls && (
          <Bone className="mt-32px radius-8" pw={100} h={40} />
        )
      }
      {
        withTabs && (
          <Bone className="mt-24px radius-4" pw={100} h={33} />
        )
      }
      <Grid
        className={withTabs ? 'pt-24px' : null}
        columns={2}
        gutter={8}
        justify="center"
        align="stretch"
      >
        {
          list.map((index) => (
            <Bone
              key={index}
              className="radius-16"
              pw={100}
              h={114}
            />
          ))
        }
      </Grid>
    </>
  )
}


export default AdminCountersPageSkeleton
