import React from 'react'
import cx from 'classnames'

import { Bone } from 'components/layout'

import s from './AdminImmortalPageSkeleton.scss'


export type AdminImmortalPageSkeletonProps = {
  withControls?: boolean
}

const AdminImmortalPageSkeleton: React.FC<AdminImmortalPageSkeletonProps> = (props) => {
  const { withControls = true } = props

  return (
    <>
      {
        withControls && (
          <>
            <Bone w={63} h={20} />
            <Bone className="mt-36px radius-4" pw={30} textSize="h28" />
          </>
        )
      }
      <div className="mt-32px flex items-start">
        <div className="flex-1">
          <div className="flex">
            <Bone className="flex-none relative" w={104} h={104} circle />
            <div className="ml-24px flex-1">
              <Bone className="radius-8" pw={100} h={40} />
              <Bone className="radius-8 mt-24px" pw={100} h={40} />
            </div>
          </div>
          <Bone className="radius-8 mt-24px" pw={100} h={40} />
          <div className="mt-36px flex">
            <Bone className="radius-8 flex-1" h={40} />
            <Bone className="radius-8 flex-1 ml-16px" h={40} />
          </div>
          <div className="mt-36px flex">
            <Bone className="radius-8 flex-1" h={40} />
            <Bone className="radius-8 flex-1 ml-16px" h={40} />
          </div>
        </div>
        <Bone className="ml-24px flex-1 radius-8" h={320} />
      </div>
      <div
        className={cx(s.calcWidth, 'flex pl-32px pr-40px pt-32px pb-40px shadow-titanic-1 fixed bottom-0 right-0')}
      >
        <Bone className="radius-8 flex-1" h={40} />
        <Bone className="radius-8 flex-1 ml-24px" h={40} />
      </div>
    </>
  )
}


export default AdminImmortalPageSkeleton
