import React from 'react'

// import { useQuery } from 'hooks'


const InitialData: React.FC = ({ children }) => {
  // const { data } = useQuery('')

  // if (!data) {
  //   return null
  // }

  return (
    <>
      {children}
    </>
  )
}


export default InitialData
