import links from 'links'
import { checkWebView } from 'helpers'
import React, { useEffect } from 'react'
import { usePathname, useSearchParams } from 'router'

import { Bone } from 'components/layout'


const LoginResultPage = () => {
  const pathname = usePathname()
  const { msg } = useSearchParams()
  const isWebView = checkWebView(!__SERVER__ && navigator.userAgent)

  useEffect(() => {
    const isSuccessPage = pathname === links.loginSuccess
    const isErrorPage = pathname === links.loginError
    const onLoginSuccess = isWebView ? () => window.location.href = links.home : window.opener?.onLoginSuccess
    const onLoginError = isWebView ? () => window.location.href = links.home : window.opener?.onLoginError

    if (isSuccessPage && onLoginSuccess) {
      onLoginSuccess()
    }

    if (isErrorPage && onLoginError) {
      onLoginError(msg)
    }

    if (!isWebView) {
      window.close()
    }
  }, [ pathname, msg, isWebView ])

  return (
    <Bone
      className="fixed top-0 left-0"
      pw={100}
      ph={100}
    />
  )
}


export default LoginResultPage
