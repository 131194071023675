import React from 'react'
import { closeModal, modalVisibility, ModalVisibilityProps, openModal } from 'modal'
import { useDevice } from 'device'
import cx from 'classnames'

import { Modal } from 'components/feedback'
import { Style, Text } from 'components/dataDisplay'
import { Form, FormState } from 'components/inputs'

import ColoredInput from '../ColoredInput/ColoredInput'
import ColoredButton from '../ColoredButton/ColoredButton'
import ColoredTextarea from '../ColoredTextarea/ColoredTextarea'
import CropPhotoModal from '../CropPhotoModal/CropPhotoModal'

import bgImage from '../../../containers/ImmortalsContainer/images/bg.jpg'
import bgImage2x from '../../../containers/ImmortalsContainer/images/bg@2x.jpg'

import UploadInput from './UploadInput/UploadInput'

import { useAddHeroModal } from './util'

import s from './AddHeroModal.scss'


const AddHeroModal: React.FC<ModalVisibilityProps> = (props) => {
  const { closeModal } = props

  const { isDesktop, isTablet } = useDevice()

  const { form, submit, isSubmitting, onFileInputChange } = useAddHeroModal()

  const buttonNode = (
    <FormState form={form}>
      {
        ({ values }) => (
          <ColoredButton
            className="mt-48px w-full"
            title="Опубликовать"
            type="submit"
            disabled={isSubmitting || !values.firstName || !values.lastName}
          />
        )
      }
    </FormState>
  )

  let formNode

  if (isDesktop) {
    formNode = (
      <Form className="flex mt-32px" disabled={isSubmitting} onSubmit={submit}>
        <div className={s.upload}>
          <UploadInput
            className="w-full"
            field={form.fields.image}
            disabled={isSubmitting}
            onFileInputChange={onFileInputChange}
          />
        </div>
        <div className="flex-1 ml-32px">
          <ColoredInput
            label="Фамилия"
            field={form.fields.lastName}
            mask="capitalize"
          />
          <ColoredInput
            className="mt-24px"
            label="Имя"
            field={form.fields.firstName}
            mask="capitalize"
          />
          <ColoredInput
            className="mt-24px"
            label="Отчество"
            field={form.fields.middleName}
            mask="capitalize"
          />
          <div className="flex mt-40px">
            <ColoredInput
              className="flex-1"
              label="Год рождения"
              field={form.fields.birthYear}
              mask="year_1800_1990"
            />
            <ColoredInput
              className="flex-1 ml-32px"
              label="Год смерти"
              field={form.fields.deathYear}
              mask="year_1900_now"
            />
          </div>
          <ColoredInput
            className="mt-40px"
            label="Воинское звание"
            field={form.fields.sign}
            mask="capitalize"
          />
          <ColoredInput
            className="mt-24px"
            label="Воинская специальность"
            field={form.fields.specialty}
            mask="capitalize"
          />
          <ColoredTextarea
            className="mt-40px"
            label="История героя"
            field={form.fields.description}
            rows={10}
            mask="capitalize"
          />
          {buttonNode}
        </div>
      </Form>
    )
  }
  if (isTablet) {
    formNode = (
      <Form className="mt-32px" disabled={isSubmitting} onSubmit={submit}>
        <div className="flex">
          <div className={s.upload}>
            <UploadInput
              className="w-full"
              field={form.fields.image}
              disabled={isSubmitting}
              onFileInputChange={onFileInputChange}
            />
          </div>
          <div className="flex-1 ml-32px">
            <ColoredInput
              label="Фамилия"
              field={form.fields.lastName}
              mask="capitalize"
            />
            <ColoredInput
              className="mt-24px"
              label="Имя"
              field={form.fields.firstName}
              mask="capitalize"
            />
            <ColoredInput
              className="mt-24px"
              label="Отчество"
              field={form.fields.middleName}
              mask="capitalize"
            />
          </div>
        </div>
        <ColoredInput
          className="mt-40px"
          label="Год рождения"
          field={form.fields.birthYear}
          mask="year_1800_1990"
        />
        <ColoredInput
          className="mt-24px"
          label="Год смерти"
          field={form.fields.deathYear}
          mask="year_1900_now"
        />
        <ColoredInput
          className="mt-40px"
          label="Воинское звание"
          field={form.fields.sign}
          mask="capitalize"
        />
        <ColoredInput
          className="mt-24px"
          label="Воинская специальность"
          field={form.fields.specialty}
          mask="capitalize"
        />
        <ColoredTextarea
          className="mt-40px"
          label="История героя"
          field={form.fields.description}
          rows={10}
          mask="capitalize"
        />
        {buttonNode}
      </Form>
    )
  }
  if (!isDesktop && !isTablet) {
    formNode = (
      <Form className="mt-24px" disabled={isSubmitting} onSubmit={submit}>
        <div className={s.upload}>
          <UploadInput
            className="w-full"
            field={form.fields.image}
            disabled={isSubmitting}
            onFileInputChange={onFileInputChange}
          />
        </div>
        <ColoredInput
          className="mt-32px"
          label="Фамилия"
          field={form.fields.lastName}
          mask="capitalize"
        />
        <ColoredInput
          className="mt-24px"
          label="Имя"
          field={form.fields.firstName}
          mask="capitalize"
        />
        <ColoredInput
          className="mt-24px"
          label="Отчество"
          field={form.fields.middleName}
          mask="capitalize"
        />
        <ColoredInput
          className="mt-40px"
          label="Год рождения"
          field={form.fields.birthYear}
          mask="year_1800_1990"
        />
        <ColoredInput
          className="mt-24px"
          label="Год смерти"
          field={form.fields.deathYear}
          mask="year_1900_now"
        />
        <ColoredInput
          className="mt-40px"
          label="Воинское звание"
          field={form.fields.sign}
          mask="capitalize"
        />
        <ColoredInput
          className="mt-24px"
          label="Воинская специальность"
          field={form.fields.specialty}
          mask="capitalize"
        />
        <ColoredTextarea
          className="mt-40px"
          label="История героя"
          field={form.fields.description}
          mask="capitalize"
          rows={10}
        />
        {buttonNode}
      </Form>
    )
  }

  return (
    <Modal
      className={cx(s.modal, 'justify-start')}
      title=""
      color="warrior"
      fullWidth
      closeModal={closeModal}
    >
      <div className="mw-618 w-full margin-auto">
        <Text
          className="mt-16px"
          size="h32"
          message="Мой герой"
          color="schindler"
        />
        <Text
          className="mt-8px opacity-48"
          size="t16-20"
          message="Размещайте информацию только об одном герое. Если хотите рассказать о двух и более, то заведите отдельную карточку для каждого."
          color="schindler"
        />
        {formNode}
      </div>
      <CropPhotoModal
        className={cx(s.modal, 'justify-start')}
        color="warrior"
      />
      <Style
        rules={[
          {
            selectors: [ s.modal ],
            bgImage,
            bgImage2x,
          },
        ]}
      />
    </Modal>
  )
}

export const openAddHeroModal = () => openModal('AddHeroModal')

export const closeAddHeroModal = () => closeModal('AddHeroModal')


export default modalVisibility('AddHeroModal', AddHeroModal)
