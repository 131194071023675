import React from 'react'
import { useSearchParams } from 'router'

import { Bone, Cols } from 'components/layout'


export type AdminAppealPageSkeletonProps = {}

const AdminAppealPageSkeleton: React.FC<AdminAppealPageSkeletonProps> = (props) => {
  const { tab } = useSearchParams()
  const withContent = !tab || tab === 'description'

  return (
    <>
      <Bone w={63} h={20} />
      <Bone className="mt-20px radius-4" pw={30} textSize="h28" />
      <div className="flex items-center mt-16px">
        <Bone className="radius-16" w={120} h={24} />
        <Bone className="ml-24px radius-4" w={204} h={16} />
      </div>
      <Bone className="mt-24px radius-4" w={467} h={33} />
      {
        withContent && (
          <>
            <Bone className="mt-32px radius-4" w={630} h={21} />
            <Cols className="mt-32px" layout="admin">
              <div>
                <Bone className="radius-16" pw={100} h={368} />
                <div className="flex mt-24px">
                  <div className="flex-1">
                    <Bone className="radius-4" w={180} textSize="s13-r" />
                    <Bone className="mt-4px radius-4" w={60} textSize="t16-20" />
                  </div>
                  <div className="flex-1">
                    <Bone className="radius-4" w={100} textSize="s13-r" />
                    <Bone className="mt-4px radius-4" w={80} textSize="t16-20" />
                  </div>
                </div>
                <div className="mt-24px">
                  <Bone className="radius-4" w={100} textSize="s13-r" />
                  <Bone className="mt-4px radius-4" pw={100} textSize="t16-20" />
                  <Bone className="radius-4" pw={90} textSize="t16-20" />
                  <Bone className="radius-4" pw={20} textSize="t16-20" />
                </div>
              </div>
              <div>
                <Bone className="radius-8" pw={100} h={40} />
                <Bone className="mt-16px radius-8" pw={100} h={40} />
                <Bone className="mt-32px radius-4" w={100} textSize="s13-r" />
                <div className="flex items-center mt-8px">
                  <Bone className="radius-100" w={32} h={32} />
                  <Bone className="ml-12px radius-4" w={70} textSize="s13-r" />
                </div>

                <Bone className="mt-16px radius-4" w={40} textSize="s13-r" />
                <div className="flex items-center mt-8px">
                  <Bone className="radius-100" w={32} h={32} />
                  <Bone className="ml-12px radius-4" w={70} textSize="s13-r" />
                </div>

                <Bone className="mt-24px radius-4" w={50} textSize="s13-r" />
                <Bone className="mt-4px radius-4" w={180} textSize="s13-r" />
              </div>
            </Cols>
          </>
        )
      }
    </>
  )
}


export default AdminAppealPageSkeleton
