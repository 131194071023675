import React from 'react'
import cx from 'classnames'
import { array } from 'helpers'
import { useDevice } from 'device'

import { Bone } from 'components/layout'


const getRandom = (min, max) => Math.floor(Math.random() * (max - min) + min)

const tags = array.range(0, 2).map((index) => index ? getRandom(120, 180) : 60)

const Tags = ({ className }) => (
  <div className={className}>
    {
      tags.map((width, index) => (
        <Bone
          key={index}
          className={cx('radius-16', { 'ml-8px': index })}
          w={width}
          h={24}
        />
      ))
    }
  </div>
)

const List = ({ className }) => (
  <div className={className}>
    {
      array.range(0, 5).map((index) => (
        <Bone
          key={index}
          className={cx('radius-8', { 'mt-24px': index })}
          pw={100}
          h={74}
        />
      ))
    }
  </div>
)

const PageSkeleton = () => {
  const { isMobile, isTablet } = useDevice()

  if (isTablet || isMobile) {
    return (
      <>
        <Bone
          className="radius-24"
          pw={100}
          h={112}
        />
        <div className="flex justify-center">
          <Bone
            className="radius-8"
            w={173}
            h={40}
            style={{ marginTop: '-20px' }}
          />
        </div>
      </>
    )
  }

  return (
    <>
      <Tags className="flex" />
      <div className="flex">
        <List className="flex-1 mt-32px" />
        <Bone
          className="mt-32px ml-32px radius-24"
          pw={57.25}
          h={648}
        />
      </div>
    </>
  )
}


export default PageSkeleton
