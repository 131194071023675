import { useCallback, useEffect } from 'react'
import { useForm } from 'formular'
import { compareDate, required } from 'helpers/validators'

import { useImmortal, useMyImmortals } from 'models/immortals'

import { openInfoModal } from '../../InfoModal/InfoModal'
import { openCropPhotoModal } from '../../CropPhotoModal/CropPhotoModal'

import { closeAddHeroModal } from '../AddHeroModal'


const useAddHeroModal = () => {
  const { hero } = useMyImmortals()
  const { form, submit, isSubmitting } = useImmortal(hero?.id ? String(hero.id) : null)

  useEffect(() => {
    if (hero?.id) {
      form.setValues(hero)
    }
  }, [ hero, form ])

  const handleSubmit = useCallback(async () => {
    const { errors, data } = await submit()

    if (!errors) {
      openInfoModal({
        title: 'Информация отправлена',
        text: 'Информация о новом герое отправлена на модерацию. В ближайшее время мы ознакомимся с ней и разместим на сайте.',
        // icon: '',
      })

      closeAddHeroModal()
    }
  }, [ submit ])

  const onFileInputChange = useCallback((files) => {
    const file = files[0]
    const allowedMimes = [ 'image/jpeg', 'image/png' ]

    if (!file || !allowedMimes.includes(file.type)) {
      form.fields.image.setError('Принимаются форматы изображений jpg или png')
      return
    }

    const reader = new FileReader()

    reader.addEventListener('load', () => {
      openCropPhotoModal({
        imageBase64: reader.result as string,
        field: form.fields.image,
        message: 'Кадрируйте снимок так, чтобы было хорошо видно лицо',
        aspect: 1,
      })
    })

    reader.readAsDataURL(file)
  }, [ form ])

  return {
    form,
    submit: handleSubmit,
    isSubmitting,
    onFileInputChange,
  }
}


export default useAddHeroModal
