import { useCallback, useEffect, useState } from 'react'

import audio from './audio'


const cache = []

const preload = (src) => {
  if (cache[src]) {
    return Promise.resolve(src)
  }

  return new Promise((resolve) => {
    const link = document.createElement('link')
    link.rel = 'prefetch'
    link.href = src
    link.onload = () => {
      cache.push(src)
      resolve(src)
    }
    document.head.appendChild(link)
  })
}

const useAudioPreload = () => {
  // const [ loaded, setLoaded ] = useState(
  //   audio.filter((src) => cache.includes(src))
  // )

  const handleLoadQueue = useCallback((index = 0) => {
    if (audio[index]) {
      preload(audio[index].src)
        .then(() => handleLoadQueue(index + 1))
    }
  }, [])

  useEffect(() => {
    handleLoadQueue()
    // audio.forEach(({ id, title, size, src }) => {
    //   if (!cache[src]) {
    //     preload(src)
    //       .then(() => {
    //         setLoaded((state) => [ ...state, { id, title, size, src } ])
    //       })
    //   }
    // })
  }, [ handleLoadQueue ])

  // return {
  //   // loaded,
  //   audio,
  // }
}


export default useAudioPreload
