import { useCallback, useEffect, useRef, useState } from 'react'
import dayjs from 'date'
import config from 'config'
import { resolveRef } from 'helpers/getters'
import { usePathname } from 'router'

import audio from './audio'


const formatDuration = (duration: number) => {
  return dayjs().startOf('day').add(duration, 'seconds').format('mm:ss')
}

const space = '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'

const clearTitle = (titleRef, isResize = false) => {
  titleRef.current.style.left = ''
  titleRef.current.dataset['width'] = ''
  titleRef.current.classList.remove('textAnimation')

  if (isResize) {
    titleRef.current.innerHTML = titleRef.current.innerHTML.replace(new RegExp(`${space}.*`), '')
  }
}

const handleTitleOverflow = (titleRef) => {
  const isTitleOverflow = titleRef.current.clientWidth > titleRef.current.parentElement.clientWidth
  const currentOffset = Number(titleRef.current.style.left.replace('px', ''))

  if (isTitleOverflow) {
    if (!titleRef.current.style.left) {
      const title = titleRef.current.innerHTML
      titleRef.current.innerHTML += space
      titleRef.current.dataset['width'] = `${titleRef.current.clientWidth}`
      titleRef.current.innerHTML += title
      titleRef.current.classList.add('textAnimation')
    }

    // Move {firstPart + space} to start
    const offsetLeft = Number(titleRef.current.dataset['width']) + currentOffset

    if (offsetLeft === 0) {
      titleRef.current.classList.add('noTransition')
      titleRef.current.style.left = `-1px`
    }
    else {
      titleRef.current.classList.remove('noTransition')
      titleRef.current.style.left = `${currentOffset - 1}px`
    }
  }
}

const useAudioPlayer = () => {
  const pathname = usePathname()

  const [ isPlaying, setPlaying ] = useState(false)
  const [ trackIndex, setTrackIndex ] = useState(0)
  const { id, title, size, src } = audio[trackIndex]

  const intervalRef = useRef()

  // Player refs
  const trackRef = useRef<HTMLDivElement>()
  const titleRef = useRef<HTMLDivElement>()
  const durationRef = useRef<HTMLDivElement>()

  // Audio refs
  const audioRef = useRef(null)
  const trackIndexRef = useRef(trackIndex)
  trackIndexRef.current = trackIndex

  const resolveRefs = useCallback(() => (
    Promise.all([
      resolveRef(trackRef),
      resolveRef(titleRef),
      resolveRef(durationRef),
    ])
  ), [])

  const renderPlayer = useCallback(() => {
    resolveRefs()
      .then(() => {
        const { duration, currentTime } = audioRef.current

        if (duration) {
          durationRef.current.innerHTML = formatDuration(duration)
        }

        trackRef.current.style.width = duration ? `${(currentTime / duration) * 100}%` : '0%'
        clearTitle(titleRef)
        titleRef.current.innerHTML = audio[trackIndexRef.current].title
      })
  }, [ resolveRefs ])

  const initAudio = useCallback((src) => {
    audioRef.current = new Audio(src)
    audioRef.current.volume = 1
    audioRef.current.onloadedmetadata = renderPlayer
  }, [ renderPlayer ])

  const fadeOutVolume = useCallback(() => {
    const interval = setInterval(() => {
      if (audioRef.current.volume) {
        audioRef.current.volume = (audioRef.current.volume * 100 - 10) / 100
      }
      else {
        clearInterval(interval)
        setPlaying(false)
        audioRef.current.volume = 1
      }
    }, 500)
  }, [])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (audioRef.current) {
        audioRef.current.pause()
        initAudio(src)
        audioRef.current.play()
      }
      else {
        initAudio(src)
      }

      if (config.env !== 'prod') {
        // @ts-ignore
        window.audio = audioRef
      }
    }
  }, [ src, initAudio ])

  const setTrackProgress = useCallback((trackProgress) => {
    const { duration } = audioRef.current

    if (trackRef.current) {
      trackRef.current.style.width = duration ? `${(trackProgress / duration) * 100}%` : '0%'
    }
  }, [ trackRef ])

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play()

      intervalRef.current = setInterval(() => {
        handleTitleOverflow(titleRef)

        if (audioRef.current.ended) {
          const nextIndex = trackIndexRef.current + 1

          setTrackIndex(audio[nextIndex] ? nextIndex : 0)
        }
        else {
          setTrackProgress(audioRef.current.currentTime)
        }
      }, 250)
    }

    return () => {
      audioRef.current?.pause()
      clearInterval(intervalRef.current)
    }
  }, [ isPlaying, setTrackProgress ])

  useEffect(() => {
    renderPlayer()
  }, [ pathname, renderPlayer ])

  useEffect(() => {
    const resetTitle = () => {
      renderPlayer()

      if (titleRef.current) {
        clearTitle(titleRef, true)
        handleTitleOverflow(titleRef)
      }
    }

    window.addEventListener('resize', resetTitle)

    return () => {
      window.removeEventListener('resize', resetTitle)
    }
  }, [ titleRef, renderPlayer ])

  return {
    audioRefs: {
      trackRef,
      titleRef,
      durationRef,
    },
    isPlaying,
    setPlaying,
    fadeOutVolume,
  }
}


export default useAudioPlayer
