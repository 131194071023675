let AdminLayout


if (__DEV__) {
  AdminLayout = require('./Dev')
}
else {
  AdminLayout = require('./Prod')
}

module.exports = AdminLayout
