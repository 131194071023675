import React, { useState } from 'react'
import cx from 'classnames'
import { useFieldState } from 'formular'

import { Textarea, TextareaProps } from 'components/inputs'

import s from './ColoredTextarea.scss'


const ColoredTextarea: React.FC<TextareaProps> = (props) => {
  const [ isFocused, setFocused ] = useState(false)

  const { value, error } = useFieldState(props.field)

  return (
    <Textarea
      {...props}
      className={cx(s.input, props.className, {
        [s.errored]: Boolean(error),
        [s.filled]: Boolean(value),
        [s.focused]: isFocused,
      })}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
    />
  )
}


export default ColoredTextarea
