import React from 'react'
import cx from 'classnames'

import { ButtonBase, ButtonBaseProps } from 'components/inputs'
import { Text } from 'components/dataDisplay'

import s from './ColoredButton.scss'


type ColoredButtonProps = ButtonBaseProps & {
  title: string
  color?: 'jaws' | 'platoon'
}

const ColoredButton: React.FC<ColoredButtonProps> = (props) => {
  const { className, title, color = 'platoon', disabled, ...rest } = props

  return (
    <ButtonBase
      className={cx(className, s.button, s[color], 'flex items-center radius-8', {
        [s.disabled]: disabled,
      })}
      {...rest}
    >
      <Text
        message={title}
        size="c16"
        color={color === 'platoon' ? 'white' : 'platoon'}
      />
    </ButtonBase>
  )
}


export default ColoredButton
