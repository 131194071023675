import React from 'react'
import links, { routeData } from 'links'

import { useUser } from 'models/user'
import useAccess from 'models/user/useAccess'

import { Redirect, usePathname, useSearchParams } from 'router'

import { AdminHomePageSkeleton } from 'compositions/admin/mapLayers'


type HasAccessProps = {
  link: string
  roles: UserModel.Role[]
  fallback?: React.FC
}

const HasAccess: React.FC<HasAccessProps> = (props) => {
  const { link, roles, fallback: Fallback, children } = props

  const tag = routeData[link]?.tag

  const pathname = usePathname()
  const searchParams = useSearchParams()

  const { hasAuth } = useUser()
  const { role, isFetching } = useAccess(tag)
  const from = `${pathname}${Object.keys(searchParams).length ? `?${(new URLSearchParams(searchParams)).toString()}` : ''}`

  if (!hasAuth) {
    const login = `${links.login}?redirect=${encodeURIComponent(from)}`

    return (
      <Redirect to={login} />
    )
  }
  else if (isFetching) {
    return (
      <AdminHomePageSkeleton />
    )
  }
  else if (tag && !roles.includes(role)) {
    return (
      Fallback ? (
        <Fallback />
      ) : (
        <Redirect to={links.page403}/>
      )
    )
  }

  if (typeof children === 'function') {
    return children({ role })
  }

  return children
}


export default HasAccess
