import config from 'config'


const audio = [
  {
    id: '001',
    title: 'Роберт Рождественский – Реквием',
    size: 12,
    src: `${config.storage}/common/audio/001.mp3`,
  },
  {
    id: '002',
    title: 'Олег Газманов - Бессмертный полк',
    size: 6.1,
    src: `${config.storage}/common/audio/002.mp3`,
  },
  {
    id: '003',
    title: 'Булат Окуджава - Десятый наш десантный батальон',
    size: 8.9,
    src: `${config.storage}/common/audio/003.mp3`,
  },
  {
    id: '004',
    title: 'Лев Лещенко - День Победы',
    size: 9.4,
    src: `${config.storage}/common/audio/004.mp3`,
  },
  {
    id: '005',
    title: 'Юрий Гуляев - На безымянной высоте',
    size: 10.9,
    src: `${config.storage}/common/audio/005.mp3`,
  },
  {
    id: '006',
    title: 'Ансамбль имени А. Александрова - Священная война',
    size: 8.3,
    src: `${config.storage}/common/audio/006.mp3`,
  },
  // {
  //   id: '007',
  //   title: '7. Singer - song title',
  //   size: 8.9,
  //   src: `${config.storage}/common/audio/007.mp3`,
  // },
  {
    id: '008',
    title: 'Марк Бернес – Журавли',
    size: 10,
    src: `${config.storage}/common/audio/008.mp3`,
  },
  {
    id: '009',
    title: 'Евгений Кибкало - Не стареют душой ветераны',
    size: 10.1,
    src: `${config.storage}/common/audio/009.mp3`,
  },
  {
    id: '010',
    title: 'Клавдия Шульженко - Синий платочек',
    size: 6.3,
    src: `${config.storage}/common/audio/010.mp3`,
  },
  {
    id: '011',
    title: 'Марк Бернес- Темная ночь',
    size: 8.4,
    src: `${config.storage}/common/audio/011.mp3`,
  },
  {
    id: '012',
    title: 'Муслим Магомаев - Эх, дороги',
    size: 9.8,
    src: `${config.storage}/common/audio/012.mp3`,
  },
  {
    id: '013',
    title: 'Ярослав Евдокимов – Майский вальс',
    size: 8.9,
    src: `${config.storage}/common/audio/013.mp3`,
  },
  {
    id: '014',
    title: 'Иосиф Кобзон - За того парня',
    size: 10.2,
    src: `${config.storage}/common/audio/014.mp3`,
  },
  {
    id: '015',
    title: 'Геннадий Белов – В землянке',
    size: 6.9,
    src: `${config.storage}/common/audio/015.mp3`,
  },
]


export default audio
