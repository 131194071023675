let MainLayout

if (__DEV__) {
  MainLayout = require('./Dev')
}
else {
  MainLayout = require('./Prod')
}

module.exports = MainLayout
