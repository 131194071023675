import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { modalVisibility, ModalVisibilityProps, openModal } from 'modal'
import type { Field } from 'formular'
import cx from 'classnames'

import ReactCrop from 'local_modules/react-image-crop'

import { Text } from 'components/dataDisplay'
import { ActionButton } from 'components/inputs'
import { Modal, ModalProps } from 'components/feedback'

import 'local_modules/react-image-crop/ReactCrop.scss'

import ColoredButton from '../ColoredButton/ColoredButton'

import { useCrop } from './util'

import s from './CropPhotoModal.scss'


type CropPhotoModalProps = {
  className?: string
  color?: ModalProps['color']
  imageBase64: string
  field: Field<string>
  message: string
  aspect?: 1.6 | 1
}

const rotateImage = async (srcBase64, degrees) => {
  return new Promise((resolve) => {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    const image = new Image()

    image.onload = () => {
      canvas.width = degrees % 180 === 0 ? image.width : image.height
      canvas.height = degrees % 180 === 0 ? image.height : image.width

      ctx.translate(canvas.width / 2, canvas.height / 2)
      ctx.rotate(degrees * Math.PI / 180)
      ctx.drawImage(image, image.width / -2, image.height / -2)

      resolve(canvas.toDataURL())
    }

    image.src = srcBase64
  })
}

const CropPhotoModal: React.FC<CropPhotoModalProps & ModalVisibilityProps> = (props) => {
  const {
    className, color, field, imageBase64, message, aspect = 1, closeModal,
  } = props

  const [ rotation, setRotation ] = useState(0)
  const [ rotatedImage, setRotatedImage ] = useState(imageBase64)

  const rotate = useCallback(() => {
    const nextRotation = rotation + 90

    setRotation(nextRotation === 360 ? 0 : nextRotation)
  }, [ rotation ])

  useEffect(() => {
    if (rotation) {
      rotateImage(imageBase64, rotation)
        .then((rotatedImage: string) => setRotatedImage(rotatedImage))
    }
    else {
      setRotatedImage((imageBase64))
    }
  }, [ rotation, imageBase64 ])

  const {
    crop,
    isImageLoaded,
    getCroppedImage,
    onImageLoad,
    onCropChange,
  } = useCrop({ aspect })

  const handleSubmit = useCallback(async () => {
    const base64 = getCroppedImage()

    field.set(base64)

    closeModal()
  }, [ field, getCroppedImage, closeModal ])

  return (
    <Modal
      className={className}
      title=""
      color={color}
      closeModal={closeModal}
    >
      <div className="mw-618 w-full margin-auto text-center">
        <ReactCrop
          className={cx(s.container, {
            [s.loaded]: isImageLoaded,
          })}
          aspect={aspect}
          crop={crop}
          circularCrop
          keepSelection
          onChange={onCropChange}
        >
          <img
            className="w-full radius-16"
            src={rotatedImage}
            onLoad={onImageLoad}
          />
        </ReactCrop>
        <ActionButton
          className="mt-12px"
          title="Повернуть на 90°"
          icon="communication/redo_16"
          color="platoon"
          onClick={rotate}
        />
        <Text
          className="mt-12px opacity-72 text-center"
          message={message}
          size="t16-20"
        />
        <ColoredButton
          className="mt-40px w-full"
          color="jaws"
          title="Сохранить"
          onClick={handleSubmit}
        />
      </div>
    </Modal>
  )
}


export const openCropPhotoModal = (props: CropPhotoModalProps) => openModal('cropPhotoModal', props)

export default modalVisibility('cropPhotoModal', CropPhotoModal)
