import React, { useEffect } from 'react'

import { useSingleAccessLink } from 'models/admin'


const AdminLayout: React.FC = ({ children }) => {
  const singleAccessLink = useSingleAccessLink()

  useEffect(() => {
    if (singleAccessLink) {
      const root = document.getElementById('root')

      if (root) {
        root.style.borderTopLeftRadius = '0'
        root.style.maxWidth = '100%'

        return () => {
          if (root) {
            root.style.borderTopLeftRadius = ''
            root.style.maxWidth = ''
          }
        }
      }
    }
  }, [ singleAccessLink ])

  return (
    <>
      {children}
    </>
  )
}


export default AdminLayout
