import { useMemo } from 'react'
import { useImmortals } from 'models/immortals/index'
import { array } from 'helpers'


const useMyImmortals = () => {
  const { hero: myHero, heroes: myHeroes, isFetching: isMyHeroesFetching } = useImmortals({ isMy: true })
  const { hero: publishedHero, heroes: publishedHeroes, isFetching: isPublishedHeroesFetching } = useImmortals()

  const heroData = myHero || publishedHero
  const hero = heroData?.deleted ? null : heroData
  const isFetching = isMyHeroesFetching || isPublishedHeroesFetching

  const heroes = useMemo(() => {
    if (isFetching) {
      return []
    }

    const myHeroIds = myHeroes.map(({ id }) => id)
    const filteredHeroes = publishedHeroes
      .map((hero) => ({
        ...hero,
        isMy: myHeroIds.includes(hero.id),
      }))
      .reduce((result, hero) => {
        result[hero.uuid] = (result[hero.uuid] || []).concat(hero)

        return result
      }, [])

    const filteredHeroesArray = Object.keys(filteredHeroes)
      .reduce((result, key) => result.concat([ filteredHeroes[key] ]), [])

    return array.shuffle(filteredHeroesArray)
      .reduce((result, arr) => result.concat(arr), [])
      .sort((a, b) => a.isMy ? -1 : b.isMy ? 1 : 0)
  }, [ myHeroes, publishedHeroes, isFetching ])

  return {
    hero,
    heroes,
    isFetching,
  }
}


export default useMyImmortals
