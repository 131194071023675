import { PixelCrop } from 'local_modules/react-image-crop'

import canvasPreview from './canvasPreview'


let previewUrl = ''

// function toBlob(canvas: HTMLCanvasElement): Promise<Blob> {
//   return new Promise((resolve) => {
//     canvas.toBlob(resolve)
//   })
// }

// Returns an image source you should set to state and pass
// `{previewSrc && <img alt="Crop preview" src={previewSrc} />}`
const imagePreview = (
  image: HTMLImageElement,
  crop: PixelCrop,
  scale = 1,
  rotate = 0,
  // imageRef,
) => {
  const canvas = document.createElement('canvas')
  canvasPreview(image, canvas, crop, scale, rotate)

  // const blob = await toBlob(canvas)
  if (previewUrl) {
    URL.revokeObjectURL(previewUrl)
  }

  previewUrl = canvas.toDataURL('image/jpeg')//URL.createObjectURL(blob)

  // imageRef.src = previewUrl

  return previewUrl
}


export default imagePreview
