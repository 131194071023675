import dayjs from 'date'


const modifyFormValues = (values: Immortals.Form): Immortals.SubmitHero => ({
  firstName: values.firstName || null,
  lastName: values.lastName || null,
  middleName: values.middleName || null,
  sign: values.sign || null,
  specialty: values.specialty || null,
  description: values.description || null,
  birth: values.birthYear ? dayjs(values.birthYear, 'YYYY').format('YYYY-MM-DD') : null,
  death: values.deathYear ? dayjs(values.deathYear, 'YYYY').format('YYYY-MM-DD') : null,
  image: /immortals/.test(values.image) ? null : values.image.replace(/.*base64,/, ''),
})


export default modifyFormValues
