import { useCallback, useMemo } from 'react'
import { useMutation } from 'hooks'
import links from 'links'
import { useHistory, usePathname } from 'router'

import { modifyFormValues } from 'models/immortals'

import useImmortalForm from './useImmortalForm'


const useImmortal = (id?: string) => {
  const pathname = usePathname()
  const form = useImmortalForm()
  const history = useHistory()
  const isAdmin = /admin/.test(pathname)

  const queryOptions = useMemo(() => ({
    url: '/immortals/rest/',
  }), [])

  const [ submit, { isSubmitting } ] = useMutation(queryOptions)

  const [ remove, { isSubmitting: isRemoving } ] = useMutation({
    url: `/immortals/rest/`,
    method: 'DELETE',
  })

  const handleRemove = useCallback(async () => {
    const { data, cacheStorage } = await remove({
      url: `/immortals/rest/${id}`,
    })

    cacheStorage.reset('/immortals/rest/')
    cacheStorage.reset('/immortals/rest/own')
    cacheStorage.reset('/immortals/rest/moderate')

    if (id) {
      history.replace(isAdmin ? links.admin.immortals.root : links.immortals.root)
    }

    return {
      data,
    }
  }, [ id, isAdmin, remove, history ])

  const handleSubmit = useCallback(async () => {
    const { values, errors } = await form.submit()

    if (errors) {
      return { errors }
    }

    const modifiedValues = modifyFormValues(values)

    const { data, cacheStorage } = await submit({
      url: id ? `/immortals/rest/${id}` : `/immortals/rest/`,
      body: modifiedValues,
      method: id ? 'PUT' : 'POST',
    })

    cacheStorage.reset('/immortals/rest/')
    cacheStorage.reset('/immortals/rest/own')
    cacheStorage.reset('/immortals/rest/moderate')

    if (id) {
      history.push(isAdmin ? links.admin.immortals.root : links.immortals.root)
    }

    return { data }
  }, [ id, isAdmin, form, submit, history ])

  return {
    form,
    submit: handleSubmit,
    remove: handleRemove,
    isRemoving,
    isSubmitting,
  }
}


export default useImmortal
