import './setPublicPath'
import * as preact from 'preact'
import Root from './root'
// import Root from './demo/root.component'
import singleSpaPreact from 'single-spa-preact'
// import { loadableReady } from '@loadable/component'


// loadableReady()

const preactLifecycles = singleSpaPreact({
  preact,
  rootComponent: Root,
})


export const bootstrap = preactLifecycles.bootstrap
export const mount = preactLifecycles.mount
export const unmount = preactLifecycles.unmount
