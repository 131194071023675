import React from 'react'
import links from 'links'
import { useUser } from 'models/user'
import { Redirect, usePathname, useSearchParams } from 'router'

import { AdminHomePageSkeleton } from 'compositions/admin/mapLayers'


type HasUserPropertyProps = {
  propertyName: 'adminEditAccess' | 'adminAccess' | 'root'
}

const HasUserProperty: React.FC<HasUserPropertyProps> = (props) => {
  const { children, propertyName } = props

  const pathname = usePathname()
  const searchParams = useSearchParams()

  const { user, hasAuth, isUserFetching } = useUser()

  const from = `${pathname}${Object.keys(searchParams).length ? `?${(new URLSearchParams(searchParams)).toString()}` : ''}`

  if (!isUserFetching && !hasAuth) {
    const login = `${links.login}?redirect=${encodeURIComponent(from)}`

    return (
      <Redirect to={login} />
    )
  }
  else if (isUserFetching) {
    return (
      <AdminHomePageSkeleton />
    )
  }
  else if (!user[propertyName]) {
    return (
      <Redirect to={links.page403}/>
    )
  }

  return (
    <>{children}</>
  )
}


export default HasUserProperty
