import React, { RefObject, useContext } from 'react'

import useAudioPreload from './util/useAudioPreload'
import useAudioPlayer from './util/useAudioPlayer'


type AudioContext = {
  audioRefs: {
    trackRef: RefObject<HTMLDivElement>
    titleRef: RefObject<HTMLDivElement>
    durationRef: RefObject<HTMLDivElement>
  }
  isPlaying: boolean
  setPlaying: (isPlaying: boolean) => void
  fadeOutVolume: () => void
}

const Context = React.createContext<AudioContext>(null)

export const useAudio = () => useContext<AudioContext>(Context)

const AudioContextProvider: React.FC = ({ children }) => {
  useAudioPreload()

  const context = useAudioPlayer()

  return (
    <Context.Provider
      value={context}
    >
      {children}
    </Context.Provider>
  )
}


export default AudioContextProvider
