import React from 'react'

import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import updateLocale from 'dayjs/plugin/updateLocale'
import duration from 'dayjs/plugin/duration'

import CommonData from 'compositions/CommonData/CommonData'

import InitialData from './InitialData/InitialData'


dayjs.extend(customParseFormat)
dayjs.extend(updateLocale)
dayjs.extend(duration)

const App = ({ children }) => {
  return (
    <>
      {
        Boolean(__DEV__) && (
          <CommonData />
        )
      }
      <InitialData>
        {children}
      </InitialData>
    </>
  )
}


export default App
