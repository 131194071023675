import React from 'react'
import { Field, useFieldState } from 'formular'
import cx from 'classnames'

import { Icon, Text } from 'components/dataDisplay'
import { ButtonBase } from 'components/inputs'

import CircleButton from '../../CircleButton/CircleButton'


type UploadInputProps = {
  className?: string
  field: Field<string>
  disabled?: boolean
  onFileInputChange: (file: any) => void
}

const UploadInput: React.FC<UploadInputProps> = (props) => {
  const { className, field, disabled, onFileInputChange } = props

  const { value } = useFieldState(field)

  return (
    <CircleButton
      className={cx(className, {
        'cursor-default': Boolean(value) || disabled,
        'opacity-48': disabled,
      })}
      tooltip={value ? null : 'Без рамок и надписей, в формате jpg или png'} //, не более 8 Мб
      contentNode={(
        Boolean(value) ? (
          <>
            <img className="w-full radius-100" src={value} />
            <ButtonBase
              className="absolute bottom-0 right-0 p-8px bg-white radius-100"
              onClick={() => {
                field.set('')
              }}
            >
              <Icon name="action/close_16" color="platoon" />
            </ButtonBase>
          </>
        ) : (
          <>
            {
              !disabled && (
                <input
                  // ref={fileInputRef}
                  className="opacity-0 absolute left-0 top-0 w-full h-full z-1 pointer"
                  onChange={(event) => {
                    onFileInputChange(event.target.files)
                    event.target.value = ''
                  }}
                  type="file"
                  accept='image/*'
                />
              )
            }
            <div className="align-center">
              <Icon
                name="IT/photo_16"
                color="platoon"
              />
              <Text
                className="mt-4px"
                message="Загрузить<br />фото"
                size="c16"
                color="platoon"
                html
              />
            </div>
          </>
        )
      )}
    />
  )
}


export default UploadInput
