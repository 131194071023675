import React from 'react'
import baseLoadable from '@loadable/component'

import PageSkeleton from 'compositions/PageSkeleton/PageSkeleton'

import useLoadable from './util/useLoadable'


export const loadable = (func, props = {}) => baseLoadable(func, { fallback: <PageSkeleton />, ...props })

export const viewLoadable = ({ Root, Mobile, Desktop }) => {
  return () => {
    const { View, isLoading } = useLoadable({
      Root,
      Mobile,
      Desktop,
    })

    return isLoading ? (
      <PageSkeleton />
    ) : (
      <Root view={View} />
    )
  }
}
