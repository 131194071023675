import React from 'react'
import links, { getLinkWithParams } from 'links'
import { useHistory, useSearchParams } from 'router'


const AppealsRedirects = ({ children }) => {
  const history = useHistory()
  const { task, taskId, ...rest } = useSearchParams()

  const redirectTaskId = task || taskId
  const search = Object.keys(rest).reduce((result, key) => {
    if (!result) {
      result += `?${key}=${rest[key]}`
    }
    else {
      result += `&${key}=${rest[key]}`
    }

    return result
  }, '')

  if (redirectTaskId) {
    const replaceLink = getLinkWithParams(
      links.appeals.item.root,
      {
        itemId: redirectTaskId,
      }
    ) + search

    history.replace(replaceLink)
    return null
  }

  return children
}


export default AppealsRedirects
