import { useLayoutEffect } from 'react'
import { useDevice } from 'device'


const useBgColor = () => {
  const { isMobile, isTablet, isDesktop } = useDevice()

  useLayoutEffect(() => {
    const root = document.getElementById('page-content') || document.getElementById('root')?.children[0]

    root?.classList.add('bg-warrior')

    return () => {
      root?.classList.remove('bg-warrior')
    }
  }, [ isMobile, isTablet, isDesktop ])
}


export default useBgColor
