import { useMemo } from 'react'
import { useQuery } from 'hooks'
import config from 'config'


type MunicipalitiesApiData = {
  all: City.Municipality[]
  stat: {
    'closed': number
    'inWork': number
    'users': number
  }
}

const useMunicipalities = () => {
  const queryOptions = useMemo(() => ({
    url: `/ajax.php?action=loadCitiesTradeshow&wTerritory=1&wStat=1`,
    skip: config.isLegacyAuth,
  }), [])

  const { data, isFetching } = useQuery<MunicipalitiesApiData>(queryOptions)

  const isRootDomain = useMemo(() => {
    if (data?.all) {
      const serverFindFunction = (server) => __DEV__ ? server.includes(config.appDomain) : server === window.location.origin
      const currentMunicipality = data.all.find(({ server }) => serverFindFunction(server))

      const { hasSatellite } = currentMunicipality || {}

      return hasSatellite === '2'
    }

    return false
  }, [ data ])

  const isRegion = useMemo(() => {
    if (data?.all) {
      const serverFindFunction = (server) => __DEV__ ? server.includes(config.appDomain) : server === window.location.origin
      const currentMunicipality = data.all.find(({ server }) => serverFindFunction(server))

      const { satelliteOf } = currentMunicipality || {}

      return Boolean(satelliteOf) || isRootDomain
    }

    return false
  }, [ data, isRootDomain ])

  return {
    municipalities: data,
    isRegion,
    isRootDomain,
    isMunicipalitiesFetching: isFetching,
  }
}

export default useMunicipalities