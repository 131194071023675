import { useEffect } from 'react'
import { useForm } from 'formular'
import { compareDate, required } from 'helpers/validators'


const useImmortalForm = (values?: Partial<Immortals.Form>) => {
  const form = useForm<Immortals.Form>({
    fields: {
      firstName: {
        validate: [ required ],
        value: values?.firstName,
      },
      middleName: {
        validate: [],
        value: values?.middleName,
      },
      lastName: {
        validate: [ required ],
        value: values?.lastName,
      },
      birthYear: {
        validate: [ compareDate({ lessThan: 'deathYear' }) ],
        value: values?.birthYear,
      },
      deathYear: {
        validate: [ compareDate({ moreThan: 'birthYear' }) ],
        value: values?.deathYear,
      },
      sign: {
        validate: [],
        value: values?.sign,
      },
      specialty: {
        validate: [],
        value: values?.specialty,
      },
      description: {
        validate: [],
        value: values?.description,
      },
      image: {
        validate: [],
        value: values?.image,
      },
    },
  })

  useEffect(() => {
    if (values) {
      form.setValues(values)
    }
  }, [ values, form ])

  return form
}


export default useImmortalForm
