import React, { useMemo } from 'react'
import links from 'links'
import { usePathname, useRouter } from 'router'

import { Bone } from 'components/layout'

import {
  AdminAppealPageSkeleton,
  AdminAppealPageSkeletonProps,
  AdminAppealsPageSkeleton,
  AdminAppealsPageSkeletonProps,
} from './appeals'
import {
  AdminImmortalPageSkeleton,
  AdminImmortalPageSkeletonProps,
  AdminImmortalsPageSkeleton,
  AdminImmortalsPageSkeletonProps,
} from './immortals'
import {
  AdminCountersPageSkeleton,
} from './counters'


const layouts = {
  [links.admin.appeals.root]: AdminAppealsPageSkeleton,
  [links.admin.users.root]: AdminAppealsPageSkeleton,
  [links.admin.appeals.item]: AdminAppealPageSkeleton,
  [links.admin.immortals.root]: AdminImmortalsPageSkeleton,
  [links.admin.immortals.item]: AdminImmortalPageSkeleton,
  [links.admin.counters.root]: AdminCountersPageSkeleton,
  [links.admin.institutions.root]: AdminAppealsPageSkeleton,
  [links.admin.elections.root]: AdminAppealsPageSkeleton,
  [links.admin.police.root]: AdminAppealsPageSkeleton,
}

const layoutKeys = Object.keys(layouts)

type AdminHomePageSkeletonProps = (
  AdminAppealsPageSkeletonProps
  & AdminAppealPageSkeletonProps
  & AdminImmortalPageSkeletonProps
  & AdminImmortalsPageSkeletonProps
  & {
    withHeader?: boolean
  }
)

const AdminHomePageSkeleton: React.FC<AdminHomePageSkeletonProps> = (props) => {
  const { withHeader = true, ...layoutProps } = props
  const pathname = usePathname()
  const { matcher } = useRouter()

  const Layout = useMemo(() => {
    const match = layoutKeys.find((key) => matcher(key, pathname))

    return layouts[match]
  }, [ pathname, matcher ])

  const commonLayoutNode = withHeader && (
    <div className="flex items-center" style={{ height: '40px' }}>
      <Bone className="radius-4" pw={20} textSize="h28" />
    </div>
  )

  if (Layout) {
    return (
      <>
        <Layout
          {...layoutProps}
          commonLayoutNode={commonLayoutNode}
        />
      </>
    )
  }

  return commonLayoutNode
}


export default AdminHomePageSkeleton
