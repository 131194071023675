import React from 'react'

import AppealsRedirects from './components/AppealsRedirects/AppealsRedirects'


const MainLayout: React.FC = ({ children }) => (
  <AppealsRedirects>
    {children}
  </AppealsRedirects>
)


export default MainLayout
