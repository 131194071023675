import 'preact/debug'
import React from 'react'
import { DeviceProvider } from 'device'
import { HelmetProvider } from 'react-helmet-async'
import { Router } from 'router'
import 'focus-visible'
import { QueryProvider } from 'hooks/query/context'

import App from 'containers/App/App'
import Routes from 'containers/Routes/Routes'
import ErrorBoundary from 'containers/ErrorBoundary/ErrorBoundary'
import { AuthProvider } from 'containers/AuthProvider'


const Root = () => (
  <ErrorBoundary>
    <HelmetProvider>
      <DeviceProvider value={window.__DEVICE__}>
        <QueryProvider value={window.__QUERY_STATE__}>
          <AuthProvider>
            <Router>
              <App>
                <Routes />
              </App>
            </Router>
          </AuthProvider>
        </QueryProvider>
      </DeviceProvider>
    </HelmetProvider>
  </ErrorBoundary>
)


export default Root
